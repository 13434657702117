<template>
  <!-- 安排面试 -->
  <div class="arrangeInterview">
    <van-nav-bar title="安排面试"
                 left-text=""
                 :right-text="rightText"
                 left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight">
      <!-- <template #right>
        <van-icon name="ellipsis"
                  size="20" />
      </template> -->
    </van-nav-bar>
    <div class="search">
      <form action="/">
        <van-search v-model="value"
                    shape="round"
                    placeholder="名字/职位"
                    @search="onSearch" />
      </form>
    </div>
    <div class="person_cards"
         :style="{height:height}">
      <div v-if="noMore">
        <div class="for_div"
             v-for="(item ,index) in 6"
             :key="index">
          <van-cell-group>
            <van-cell class="detail_cell"
                      @click.stop="showPersonalInf(item)"
                      label="本人已毕业半年,现实习也已完成,但公司规模不大,想往更好的方向前进,本人已毕业半年,现实习也已完成,但公司规模不大,想往更好的方向前进">
              <!-- <template #icon>
            <van-checkbox :name="item"
                          ref="checkboxes" />
          </template> -->
              <template #title>
                <div class="cell_one">
                  <span>{{xing[index]}}先生</span>
                </div>
                <div class="cell_two">
                  <span>3年经验</span>
                  <span>|</span>
                  <span>大专</span>
                  <span>22岁</span>
                </div>
                <div class="cell_three">
                  <svg-icon icon-class="interview_wk"></svg-icon>
                  <span>佛山刁刁信息科技有限公司·</span>
                  <span>行政人员</span>
                </div>
              </template>
              <template #default>
                <div class="head_card">
                  <img src="@/assets/img/interview_pic1.png"
                       alt="" />
                </div>
              </template>
            </van-cell>
            <van-cell class="btn_cell"
                      center
                      title="人事专员">
              <template #default>
                <van-button plain
                            color="#2b8df0"
                            type="primary"
                            size="mini"
                            @click.stop="confirm(item)">安排面试</van-button>
              </template>
            </van-cell>
          </van-cell-group>
        </div>
      </div>
      <van-checkbox-group v-else
                          v-model="result"
                          ref="checkboxGroup">
        <van-checkbox :name="item"
                      icon-size="22px"
                      v-for="(item ,index) in 6"
                      :key="index">
          <!-- <template #icon="props">
            <img class="img-icon"
                 :src="props.checked ? activeIcon : inactiveIcon" />
          </template> -->
          <van-cell-group>
            <van-cell class="detail_cell"
                      label="本人已毕业半年,现实习也已完成,但公司规模不大,想往更好的方向前进,本人已毕业半年,现实习也已完成,但公司规模不大,想往更好的方向前进">
              <!-- <template #icon>
            <van-checkbox :name="item"
                          ref="checkboxes" />
          </template> -->
              <template #title>
                <div class="cell_one">
                  <span>{{xing[index]}}先生</span>
                </div>
                <div class="cell_two">
                  <span>3年经验</span>
                  <span>|</span>
                  <span>大专</span>
                  <span>22岁</span>
                </div>
                <div class="cell_three">
                  <svg-icon icon-class="interview_wk"></svg-icon>
                  <span>佛山刁刁信息科技有限公司·</span>
                  <span>行政人员</span>
                </div>
              </template>
              <template #default>
                <div class="head_card">
                  <img src="@/assets/img/interview_pic1.png"
                       alt="" />
                </div>
              </template>
            </van-cell>
            <van-cell class="btn_cell"
                      center
                      title="人事专员">
              <template #default>
                <van-button plain
                            color="#2b8df0"
                            type="primary"
                            size="mini"
                            @click.stop="confirm(item)">安排面试</van-button>
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox>
      </van-checkbox-group>
    </div>
    <div v-show="!noMore"
         class="footer_btn">
      <van-checkbox v-model="checked"
                    icon-size="22px"
                    @click="checkAll(checked)">全选</van-checkbox>
      <van-button type="info"
                  @click="confByChecked">安排面试</van-button>
      <!-- <van-submit-bar button-text="提交订单"
                      @submit="onSubmit">
        <van-checkbox v-model="checked">全选</van-checkbox>
      </van-submit-bar> -->
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      xing:['陈','李','白','周','蔡','卫',],
      rightText: '多选',
      noMore: true,
      value: '',
      result: ['a', 'b'],
      checked: false,
      height: 'calc(100% - 100px)'
    }
  },
  methods: {
    onClickLeft () {
      this.$router.push("/home")
    },
    // 多选
    onClickRight () {
      this.noMore = !this.noMore
      if (this.noMore)
      {
        this.rightText = '多选'
        this.height = 'calc(100% - 100px)'
      } else
      {
        this.rightText = '单选'
        this.height = 'calc(100% - 160px)'
      }

    },
    // 搜索按钮
    onSearch (val) {

    },
    // 查看个人资料
    showPersonalInf (item) {
      this.$router.push({ path: "/viewInfo", query: { footerType: '安排面试' } })
    },
    // 安排面试快捷按钮
    confirm (item) {
      this.$router.push('/interviewNotice')
    },
    // 全选按钮
    checkAll (checked) {
      if (checked)
      {
        this.$refs.checkboxGroup.toggleAll(true)
      } else
      {
        this.$refs.checkboxGroup.toggleAll()
      }
    },
    // 多选后 安排面试按钮
    confByChecked () {
      this.$router.push('/interviewNotice')
    }
  }
}
</script>

<style lang="less" scoped>
.arrangeInterview {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;
    height: 92px;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .person_cards {
    overflow: auto;
    // height: calc(100% - 328px);
    .van-checkbox-group {
      .van-checkbox {
        margin: 20px 0;
        align-items: end;
        background-color: #fff;
      }
      ::v-deep .van-checkbox__icon {
        padding-top: 0.49777rem;
        margin-left: 10px;
      }
      ::v-deep .van-checkbox__label {
        margin-left: unset;
      }
    }
    .van-cell-group::after {
      border: unset;
    }
    .van-cell-group {
      margin: 15px 0;
      .van-cell__title {
        flex: 2.3;
      }
      .van-cell__value {
        flex: 0.7;
      }
      .detail_cell {
        padding: 0.26667rem 0.22667rem 0 0.12667rem;
        .van-cell__label {
          font-size: 22px;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
      .detail_cell::after {
        border-bottom: unset;
      }
      .btn_cell {
        padding: 0 0.12667rem;
        .van-cell__title {
          font-size: 36px;
          font-family: Source Han Sans CN;
          font-weight: 500;
        }
        .van-button {
          border-radius: 10px;
          width: 150px;
        }
        .van-button__text {
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 500;
        }
      }
    }
    .cell_one {
      display: flex;
      align-items: center;
      span {
        font-size: 40px;
        font-family: Source Han Sans CN;
        font-weight: 500;
      }
    }
    .cell_two {
      font-size: 22px;
      font-family: Source Han Sans CN;
      color: #999;
      :nth-child(2),
      :nth-child(3) {
        padding: 0 10px;
      }
    }
    .cell_three {
      font-size: 26px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      span {
        margin-right: 10px;
      }
      .svg-icon {
        font-size: 24px;
        margin-right: 18px;
      }
    }
    .cell_four {
      color: #2b8df0;
      font-family: Source Han Sans CN;
    }
    .van-cell__value {
      display: flex;
      align-items: center;
      flex-direction: column;
      .head_card {
        // position: absolute;
        // top: 38px;
        // right: 30px;
        width: 106px;
        height: 106px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .van-button {
        margin: 18px 0;
      }
    }
    .for_div {
      margin: 0.26rem 0;
      padding: 0.02rem 0.2rem;
      background-color: #fff;
    }
  }
  .footer_btn {
    width: 100%;
    padding: 0.26667rem 0;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: flex;
    background-color: #fff;
    ::v-deep .van-checkbox__icon {
      margin-left: 0.13333rem;
    }
    ::v-deep .van-checkbox__label {
      font-size: 0.48rem;
      font-family: Source Han Sans CN;
      font-weight: 500;
    }
    .van-button {
      margin-left: auto;
      margin-right: 0.60667rem;
      border-radius: 9px;
      width: 3.86667rem;
      .van-button__text {
        font-size: 0.48rem;
        font-family: Source Han Sans CN;
        font-weight: 500;
      }
    }
  }
}
</style>